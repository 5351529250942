<template>
  <div id="Page-Referendum-list">
		Page-Referendum-list
  </div>
</template>

<script>
export default {
  name: 'Page-Referendum-list',
  components: {
    
  },
  data(){
    return {
			
    };
  },
  async mounted () {
	
  },
  methods: {
		
  }
}
</script>

<style scoped lang="less">
	
</style>